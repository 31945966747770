@font-face {
  font-family: Rubik;
  src: url('../fonts/Rubik-Light.eot');
  src: url('../fonts/Rubik-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Rubik-Light.woff2') format('woff2'),
  url('../fonts/Rubik-Light.woff') format('woff'),
  url('../fonts/Rubik-Light.ttf') format('truetype'),
  url('../fonts/Rubik-Light.svg#Rubik-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  font-stretch: normal;
}

@font-face {
  font-family: Rubik;
  src: url('../fonts/Rubik-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  font-stretch: normal;
}

@font-face {
  font-family: Rubik;
  src: url('../fonts/Rubik-Regular.eot');
  src: url('../fonts/Rubik-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Rubik-Regular.woff2') format('woff2'),
  url('../fonts/Rubik-Regular.woff') format('woff'),
  url('../fonts/Rubik-Regular.ttf') format('truetype'),
  url('../fonts/Rubik-Regular.svg#Rubik-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  font-stretch: normal;
}

@font-face {
  font-family: Rubik;
  src: url('../fonts/Rubik-Italic.ttf');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  font-stretch: normal;
}

@font-face {
  font-family: Rubik;
  src: url('../fonts/Rubik-Medium.eot');
  src: url('../fonts/Rubik-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Rubik-Medium.woff2') format('woff2'),
  url('../fonts/Rubik-Medium.woff') format('woff'),
  url('../fonts/Rubik-Medium.ttf') format('truetype'),
  url('../fonts/Rubik-Medium.svg#Rubik-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  font-stretch: normal;
}

@font-face {
  font-family: Rubik;
  src: url('../fonts/Rubik-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  font-stretch: normal;
}

@font-face {
  font-family: Rubik;
  src: url('../fonts/Rubik-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
  font-stretch: normal;
}

@font-face {
  font-family: Rubik;
  src: url('../fonts/Rubik-Bold.eot');
  src: url('../fonts/Rubik-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Rubik-Bold.woff2') format('woff2'),
  url('../fonts/Rubik-Bold.woff') format('woff'),
  url('../fonts/Rubik-Bold.ttf') format('truetype'),
  url('../fonts/Rubik-Bold.svg#Rubik-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  font-stretch: normal;
}

@font-face {
  font-family: Rubik;
  src: url('../fonts/Rubik-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  font-stretch: normal;
}

@font-face {
  font-family: Rubik;
  src: url('../fonts/Rubik-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  font-stretch: normal;
}

@font-face {
  font-family: Rubik;
  src: url('../fonts/Rubik-ExtraBoldItalic.ttf');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
  font-stretch: normal;
}

@font-face {
  font-family: Rubik;
  src: url('../fonts/Rubik-Black.ttf');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  font-stretch: normal;
}

@font-face {
  font-family: Rubik;
  src: url('../fonts/Rubik-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
  font-stretch: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../fonts/SFProText-Regular.eot');
  src: url('../fonts/SFProText-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/SFProText-Regular.woff2') format('woff2'),
  url('../fonts/SFProText-Regular.woff') format('woff'),
  url('../fonts/SFProText-Regular.ttf') format('truetype'),
  url('../fonts/SFProText-Regular.svg#SFProText-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
