* {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

p {
  font-weight: 300;
  font-family: 'Rubik', sans-serif;
}

a {
  color: inherit;
  font-size: 16px;
  text-decoration: none;
  text-transform: capitalize;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
}

a:active {
  color: inherit;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
}

input {
  outline: none;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  border: none;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 9999px;
  -moz-border-radius: 9999px;
  -webkit-border-radius: 9999px;
  background-color: #8F909D;
}
