$colors: (
        "dark": #010115,
        "white": #FFFFFF,
        "error": #BB0000,
        "gray_1": #5A5B6E,
        "gray_2": #6C6C7E,
        "gray_3": #7D7E8D,
        "gray_4": #8F909D,
        "gray_5": #A1A1AC,
        "bouquet_1": #AB90A2,
        "bouquet_2": #B299A9,
        "bouquet_3": #B8A2B1,
        "bouquet_4": #BFABB8,
        "bouquet_5": #C6B4C0,
        "gray_light_1": #B3B3BC,
        "gray_light_2": #C5C5CC,
        "gray_light_3": #D6D7DB,
        "gray_light_4": #E8E8EB,
        "gray_light_5": #F6F6F6,
        "burnt_sienna_1": #EC7961,
        "burnt_sienna_2": #EE846E,
        "burnt_sienna_3": #EF8E7A,
        "burnt_sienna_4": #F19987,
        "burnt_sienna_5": #F2A494,
        "blue_charcola_1": #010220,
        "blue_charcola_2": #131430,
        "blue_charcola_3": #25253F,
        "blue_charcola_4": #36374F,
        "blue_charcola_5": #48495E,
        "resolution_blue_1": #012488,
        "resolution_blue_2": #153692,
        "resolution_blue_3": #2A479B,
        "resolution_blue_4": #3E59A5,
        "resolution_blue_5": #526AAE,
        "nav_border_color": #EEEEF1,
);

:root {
  @each $color_name, $color_value in $colors {
    --color-#{$color_name}: #{$color_value};
  }
}